<template>
  <div class="prose">
    <div class="delta mb-4 mt-6">
      Postipaketti, nouto lähimmästä Postista
    </div>
    <p>
      Asiakas noutaa tilauksen lähimmästä Postista tai muusta Postin määrittämästä toimipisteestä. Asiakas saa postilta ilmoituksen, kun lähetys on noudettavissa. Posti säilyttää lähetystä 7 vuorokautta, jonka jälkeen lähetys palautuu takaisin lähettäjälle. Mikäli tilaus sisältää useampia tuotteita, Posti voi toimittaa lähetyksen monipakettilähetyksenä. Jokaisesta lähetyksestä muodostuu oma lähetystenseurantatunnus.
    </p>
    <div class="delta mb-4 mt-6">
      Posti Kotipaketti, kuljetus sisälle asuntoon
    </div>
    <p>
      Posti toimittaa asiakkaan tilauksen sisälle asuntoon. Posti on yhteydessä asiakkaaseen toimitusajan sopimiseksi. Kuljetukset tapahtuvat suurimmissa kaupungeissa arkisin klo 8-21, muilla alueilla jakelu klo 14 mennessä. Mikäli tilaus sisältää useampia tuotteita, Posti voi toimittaa lähetyksen monipakettilähetyksenä. Jokaisesta lähetyksestä muodostuu oma lähetystenseurantatunnus.
    </p>
    <div class="delta mb-4 mt-6">
      Posti rahti, kuljetus pihalle (jakeluauton välittömään läheisyyteen)
    </div>
    <p>
      Tilaus toimitetaan pihalle tai parkkipaikalle jakeluauton välittömään läheisyyteen. Toimitusaika ilmoitetaan puhelimitse tai tekstiviestillä. Kuljetukset tapahtuvat arkisin maanantaista perjantaihin pääsääntöisesti klo 8-16 välillä, paikkakuntakohtaisia eroja on.
    </p>
    <div class="delta mb-4 mt-6">
      Posti rahti, kuljetus sisälle asuntoon
    </div>
    <p>
      Posti toimittaa asiakkaan tilauksen sisälle asuntoon. Toimitusaika ilmoitetaan puhelimitse tai tekstiviestillä. Kuljetukset tapahtuvat suurimmissa kaupungeissa arkisin klo 8-21, esim. Helsinki, Rovaniemi, Oulu, Tampere jne. muilla alueilla jakelu klo 16 mennessä. Toimituksissa voi olla paikkakuntakohtaisia eroja. Joillakin postinumeroalueilla (pienemmät paikkakunnat) tilaus toimitetaan yhdellä kuljettajalla.
    </p>
    <div class="delta mb-4 mt-6">
      Nouto myymälästä, noutopisteeltä tai keskusvarastolta
    </div>
    <p>
      Tilaus toimitetaan keskusvarastoilta myymälään tai noutopisteelle noudettavaksi. Tässä kestää arviolta <strong>5-10 arkipäivää</strong>. Suurien tuotteiden kuljetuksesta myymälään tai noutopisteelle veloitamme tilauskohtaisen toimituskulun, joka määrittyy tilauksella olevien tuotteiden mukaisesti.
    </p>
    <p>
      Nouto Lahden tai Ranuan keskusvarastolta on aina ilmainen. Huomioithan, että myös keskusvarastolta noudettaessa voi kestää arviolta 5-10 arkipäivää ennen kuin tilaus on noudettavissa, mikäli tuote toimitetaan keskusvarastojen välillä.
    </p>
    <p>
      Myymälästä, noutopisteeltä tai keskusvarastolta ilmoitetaan aina erikseen kun tilaus on noudettavissa. Noudathan tilauksen mahdollisimman pian ilmoituksen saatuasi.
    </p>
    <p>
      <NuxtLink to="/cms/myymalat">Katso noutopisteemme</NuxtLink>
    </p>
  </div>
</template>

<!-- <script setup lang="ts"></script> -->
