<template>
  <div v-if="canBePaidWithInvoiceAndPartPayment" class="border border-light rounded p-2 flex items-center gap-x-2 flex-wrap text-sm">
    <img
      class="h-6 object-cover flex-shrink min-w-0 aspect-[44/20] dark:invert dark:saturate-0"
      loading="lazy"
      src="/images/checkout/payment-icons/svea.png"
      alt="Svea Bank AB"
    >
    <div v-if="canBePaidWithPartPayment">
      Erämaksulla
      <button type="button" class="inline p-0 cursor-pointer underline underline-offset-4 decoration-grey-950/30 hover:decoration-grey-950" @click="isModalOpen = true">
        alkaen <strong>{{ eur(monthlyPayment) }}/kk</strong>
      </button>
      <BaseModal :open="isModalOpen" class="text-black" @close="isModalOpen = false">
        <div class="gamma mb-4">
          Erämaksulaskuri
        </div>
        <SveaPartPaymentCalculator :price="price" />
        <p class="mt-6">
          <NuxtLink class="inline-flex gap-1 items-center" to="/asiakaspalvelu/maksutavat">Tutustu maksutapoihimme<ArrowRightIcon class="size-4" /></NuxtLink>
        </p>
      </BaseModal>
    </div>
    <div v-else class="text-muted">
      Osta nyt, &shy;maksa 30 päivän kuluessa.
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowRightIcon } from '@heroicons/vue/24/outline'
import BaseModal from '~/components/BaseModal.vue'
import SveaPartPaymentCalculator from '~/components/product/SveaPartPaymentCalculator.vue'
import { usePartPayment } from '~/composables/usePartPayment'
import { eur } from '~/helpers/filters'

const props = defineProps({
  price: {
    type: Number,
    required: true
  }
})

const isModalOpen = ref(false)
const { price } = toRefs(props)
const { canBePaidWithPartPayment, canBePaidWithInvoiceAndPartPayment, monthlyPayment } = usePartPayment(price)
</script>
