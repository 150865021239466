<template>
  <div class="relative">
    <div
      class="relative transition-all duration-1000 overflow-hidden"
      :style="`max-height: ${limitedHeight};`"
    >
      <div ref="el">
        <slot />
      </div>
      <div v-if="isCollapsed" class="border-b bg-gradient-to-b from-transparent via-grey-50/80 to-grey-50 h-20 absolute bottom-0 w-full flex items-end justify-center" />
    </div>
    <BaseButton
      v-if="isCollapsed"
      class="absolute -bottom-[1.5em]"
      aria-expanded="false"
      aria-haspopup="true"
      look="outline"
      @click="expanded=true"
    >
      Lue lisää
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core'

const expanded = ref(false)
const el = ref(null)
const { height } = useElementSize(el)
const limitedHeight = computed(() =>
  expanded.value
    ? height.value + 50 + 'px'
    : ((props.maxHeight + props.buffer) < height.value || !height.value) ? props.maxHeight + 'px' : (props.maxHeight + props.buffer) + 'px'
)
const isCollapsed = computed(() => !expanded.value && (props.maxHeight + props.buffer) < height.value)

const props = defineProps({
  maxHeight: {
    type: Number,
    required: false,
    default: 500
  },
  buffer: {
    type: Number,
    required: false,
    default: 100
  }
})
</script>
